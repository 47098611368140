import React, { useState, useContext, useLayoutEffect, useEffect, useRef } from "react";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import AuthService from "../../../services/AuthService";
import AAECountdownInfo from "../../../types/AAECountdownClock";
import AdwCampaignBanner from "../../../types/CampaignBannerTypes/AdwCampaignBanner";
import CampaignBanner from "../../molecules/CampaignBanner/CampaignBanner";
import GoToLink from "../../molecules/GoToLink/GoToLink";
import PayDayBar from "../../molecules/PayDayBar/PayDayBar";
import styles from "./Events.module.scss";
import moment, { Moment } from "moment";
import Link from "../../atoms/Link/Link";
import AdwCalendarEventType from "../../../types/CalendarTypes/AdwCalendarEventType";
import GraphService from "../../../services/GraphService";
import DateUtils from "../../../utils/DateUtils";
import { TimeUnits } from "../../../types/CachingTypes/TimeUnits";
import { SetSpListContents } from "../../../context/ActionTypes";
import { EventInput } from "@fullcalendar/core";
import AdwFcEvent from "../../../types/CalendarTypes/AdwFcEvent";
import EventRow from "./EventRow";
import AAECountdownClock from "../../molecules/AAECountdownClock/AAECountdownClock";


export interface IEventsProps {
  eventsGrid: string;
}

const Events: React.FC<IEventsProps> = ({ eventsGrid }) => {
  
  const [appState, dispatch]: AdwContextType = useContext(AdwContext);
  //ALL event types
  const eventTypes: AdwCalendarEventType[] = appState && appState.lists && appState.lists.companyEventType && appState.lists.companyEventType.length ? appState.lists.companyEventType : [];
  const countdownInfo: AAECountdownInfo = appState && appState.lists && appState.lists.aaeCountdownClock && appState.lists.aaeCountdownClock.length && appState.lists.aaeCountdownClock[0];
  const [eventsPayDayArray, setEventsPayDayArray] = useState<EventInput[]>([]);
  const [eventsArray, setEventsArray] = useState<EventInput[]>([]);
  const eventsRef = useRef(null);

  useLayoutEffect(() => {
    if (appState?.user?.adwLoginId) {
      getNextFourEventsAndFivePaydays(0);
    }
  }, [appState?.user])

  useEffect(() => {
    window.addEventListener("resize", determineHowManyToShow);

    return () => {
      window.removeEventListener("resize", determineHowManyToShow);
    }
  }, [])

const getNextFourEventsAndFivePaydays = (numberOfTries: number = 0)=> {
  if (numberOfTries < 5) {
    if (AuthService.hasActiveSession() && appState?.user?.adwLoginId) {
      //get sharepoint list - events => Next Five Events starting today
      GraphService.getNextCompanyCalendarEvents(DateUtils.formatDateCustom(
        new Date(),
        "YYYY-MM-DD"
      ),
      "next four event",
      4,
      eventTypes,
      0,
      TimeUnits.days).then((events) => {
        if (events) {
          eventsRef.current = events;
          setEventsArray(Object.assign(events));
          dispatch(SetSpListContents("next four event", sortEventsByEventDate(events)));
        }
      }); // Graph - Events
       //get sharepoint list - events => Next Five Events starting today
      GraphService.getNextCompanyCalendarEvents(DateUtils.formatDateCustom(
        new Date(),
        "YYYY-MM-DD"
      ),
      "Pay Day",
      5,
      eventTypes,
      0,
      TimeUnits.days).then((events) => {
        if (events) {
          setEventsPayDayArray(Object.assign(events));
          dispatch(SetSpListContents("next five payday", sortEventsByEventDate(events)));
        }
 
      }); // Graph - Events
    }
  }//numberOfTries
}

  const shouldDisplayAAECountdownClock = (): boolean => {
    const now: Moment = moment();
    return (
      countdownInfo && countdownInfo.fields && countdownInfo.fields.EventDateAndTime && countdownInfo.fields.ShowCountdownClockDateAndTime &&
      moment(moment(countdownInfo.fields.EventDateAndTime)).diff(
        now,
        "seconds"
      ) >= -600 &&
      moment(moment(countdownInfo.fields.ShowCountdownClockDateAndTime)).diff(
        now,
        "seconds"
      ) <= 0
    );
  };

  
  const sortedEventArrayByDate = (eventArray:AdwFcEvent[])=>{
    return eventArray.sort((a, b) => {
      const dateA = moment(a.date).format('LLL');
      const dateB = moment(b.date).format('LLL');
      return moment(dateA).diff(dateB);
  });
    }

  const sortEventsByEventDate = (eventArray: AdwFcEvent[]): AdwFcEvent[] => {
    if (eventArray) {
      let tempEventArray =  sortedEventArrayByDate(eventArray);
      tempEventArray = tempEventArray.sort((a, b) => moment(a.start).diff(moment(b.start)));
      return tempEventArray;
    }
  }

  const determineHowManyToShow = () => {
    let eventArray: EventInput[] = eventsRef.current;
    let windowWidth: number = window.innerWidth;
    let desktopWithBanner: boolean =
      shouldDisplayAAECountdownClock() || shouldDisplayEventsCampaignBanner();
    let mobile: boolean = windowWidth <= 666;
    let tablet: boolean = windowWidth >= 667 && windowWidth <= 1024;

    if (desktopWithBanner || mobile) {
      eventArray = eventArray.slice(0, 2);
    } else if (tablet) {
      eventArray = eventArray.slice(0, 3);
    } else {
      eventArray = eventArray.slice(0, 4);
    }

    setEventsArray(eventArray);
  };

  const shouldDisplayEventsCampaignBanner = (): boolean => {
    const banners: AdwCampaignBanner[] =
      appState && appState.lists && appState.lists.campaignBanner
        ? appState.lists.campaignBanner
        : [];
    return window.innerWidth > 1024 && banners && banners.length > 0;
  };

  const linkToCalendar: string = `./event-calendar`;
  const displayCountdownClock: boolean = shouldDisplayAAECountdownClock();
  const displayCampaignBanner: boolean = !displayCountdownClock && shouldDisplayEventsCampaignBanner();
  let eventListStyles: string = styles.eventList;
  if (!displayCampaignBanner && !displayCountdownClock) {
    eventListStyles = `${styles.eventList} ${styles.eventListWithNoBanner}`;
  }

  return (
    <div
      className={`${styles.events} ${eventsGrid}`}
      id="events"
    >
      <div className={styles.toolsHeading} id="tool-Heading">
        <h4 className={styles.title}>
          <Link
            linkClasses={styles.titleLink}
            linkText="Events"
            url={linkToCalendar}
            id="events"
          />
        </h4>
      </div>
      {eventsPayDayArray.length !== 0 ? (
        <PayDayBar payDayArray={eventsPayDayArray} id="pay-day-bar" />
      ) : (
        <div className={styles.payDayFiller} />
      )}

      <div className={eventListStyles} id="event-List">
        <EventRow eventsArray={eventsArray}/>
      </div>
      <div className={styles.eventsBorder} id="event-Border" />
      <div className={styles.visiteventlink} id="event-view-all-events-link">
        <GoToLink
          linkText="View all events"
          url={linkToCalendar}
          target="_self"
          id="linkToCalendar"
        />
      </div>
      {displayCountdownClock && <AAECountdownClock />}
      {displayCampaignBanner && <CampaignBanner />}
      </div>
  );
};


export default Events;

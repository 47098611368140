import React, { useState, useContext, useRef } from "react";
import { EventInput } from "@fullcalendar/core";
import moment, { Moment } from "moment";
import AdwCampaignBanner from "../../../types/CampaignBannerTypes/AdwCampaignBanner";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import AAECountdownInfo from "../../../types/AAECountdownClock";
import styles from "./Events.module.scss";
import NoResults from "../../molecules/NoResults/NoResults";
import momenttimezone from 'moment-timezone';
import EventPopUp from "./EventPopUp";
import AddToCalendarButton from "../../molecules/AddtocalendarButton/AddToCalendarButton";

const noResultsImage: string = require("../../../assets/images/calendar-gray-icon.png");
const EventRow = ({eventsArray}): JSX.Element => {
    
  let localtz = momenttimezone.tz.guess();
    const noResultsText: string = "We can't find any events to display. Try refreshing your page.";
  
    const [appState]: AdwContextType = useContext(AdwContext);
    const countdownInfo: AAECountdownInfo = appState && appState.lists && appState.lists.aaeCountdownClock && appState.lists.aaeCountdownClock.length && appState.lists.aaeCountdownClock[0];
  
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [popUpData, setPopUpData] = useState(null);
  const [lastFocusedButton, setLastFocusedButton] = useState(null);
  const modalRef = useRef(null);

  const shouldDisplayAAECountdownClock = (): boolean => {
    const now: Moment = moment();
    return (
      countdownInfo && countdownInfo.fields && countdownInfo.fields.EventDateAndTime && countdownInfo.fields.ShowCountdownClockDateAndTime &&
      moment(moment(countdownInfo.fields.EventDateAndTime)).diff(
        now,
        "seconds"
      ) >= -600 &&
      moment(moment(countdownInfo.fields.ShowCountdownClockDateAndTime)).diff(
        now,
        "seconds"
      ) <= 0
    );
  };

  
  const shouldDisplayEventsCampaignBanner = (): boolean => {
    const banners: AdwCampaignBanner[] =
      appState && appState.lists && appState.lists.campaignBanner
        ? appState.lists.campaignBanner
        : [];
    return window.innerWidth > 1024 && banners && banners.length > 0;
  };

  
  const togglePop = (e) => {
    setOpenPopUp(!openPopUp);
    if (lastFocusedButton) {
      lastFocusedButton.focus();
    }
  };

  const openPopUpNow = (e,info: any) => {
    setLastFocusedButton(e.target);
    setPopUpData(info);
    setOpenPopUp(true);
  }
  
  const getFullDisplayMonth = (givenDate) => {
   return moment.utc(givenDate).format('MMMM');
  };

  const getDisplayDate = (givenDate)=>{
   return moment.utc(givenDate).format('D');
  }
  
  const getDisplayDay = (givenDate)=>{
    return moment.utc(givenDate).format('ddd');
  }

  const formatStartEndTimeForEvent = (startTime, endTime) => {
    let startTimeOnly = moment(startTime).tz(localtz).format(
      "h:mm A"
    );
    let endTimeOnly = moment(new Date(endTime)).tz(localtz).format(
      "h:mm A"
    );
    return startTimeOnly + ' - ' + endTimeOnly;
  }


    let noResultStyles: string = styles.noResultStyles;
    if (
      shouldDisplayAAECountdownClock() ||
      shouldDisplayEventsCampaignBanner()
    ) {
      noResultStyles = `${styles.noResultBackgroundStyles}`;
    }

    return(<>
        {eventsArray && eventsArray.length > 0 ? 
  <ul>
          {eventsArray?.map(
    (ec, index) =>
       (
        <li
          id={`event-site-${index}`}
          key={`event-site-${ec.id}`}
          onClick={(e) => {
            openPopUpNow(e, ec);
          }}
          ref={modalRef}
        >
          <button className={styles.eventWrapper} id="event-Wrapper" >
            <div
              className={styles.eventCalendar}
              id={`event-calendar-${index}`}
            >
              <div
                className={styles.eventCalendarContent}
                style={{ borderColor: `${ec.colorTag}` }}
              >
                <div className={styles.eventCalendarDay}>{getDisplayDay(ec.date)}</div>
                <div
                  className={styles.eventCalendarDate}
                  style={{ color: `${ec.colorTag}` }}
                >
                  {getDisplayDate(ec.date)}
                </div>
                <div className={styles.eventCalendarMonth}>
                 {getFullDisplayMonth(ec.date)}
                </div>
              </div>
            </div>
            <div className={styles.eventDetail}>
              <div
                className={styles.eventDetailContent}
                id={`event-Detail-Content-${index}`}
              >
                <div>
                  <div
                    className={styles.eventDetailTitle}
                    id={`event-Detail-Title-${index}`}
                  >
                    {ec.title}
                  </div>
                  <div
                    className={styles.eventDetailTime}
                    id={`event-Detail-Time-${index}`}
                  >
                  {ec.allDay ? 'All Day Event' : formatStartEndTimeForEvent(ec.start, ec.end)}
                  </div>
                </div>
                {ec && (
                  <AddToCalendarButton
                    event={ec}                  
                    id={`eventDetatailOutlook-${index}`}
                  />
                )}
              </div>
            </div>
          </button>
        </li>
      )
  )}
        {openPopUp && <EventPopUp isOpen={openPopUp} toggle={togglePop} data={popUpData} />}
</ul>
     :
     <>
        <NoResults
        imagePath={noResultsImage}
        textForDisplay={noResultsText}
        noResultsClasses={noResultStyles}
        id="Events-NoResults"
        />
     </>   
        }
   </> )
  };

  export default EventRow;
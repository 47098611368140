"use client";
import React, { useContext, useEffect, useState } from "react";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import AuthService from "../../../services/AuthService";
import CallupService from "../../../services/CallupService";
import AdwEmployee from "../../../types/CallupTypes/AdwEmployee";
import ContactInfo  from "../../molecules/ContactInfo/ContactInfo";
import HrLocation from "../../molecules/HrLocation/HrLocation";
import OrgChart from "../../molecules/OrgChart/OrgChart";
import styles from "./EmployeeDetailView.module.scss";
import AboutMe from "../../molecules/AboutMe/AboutMe";
import { EditModalType } from "../../../types/EditModalType/EditModalType";
import EmployeeDetailModal from "../../atoms/EmployeeDetailModal/EmployeeDetailModal";
import ComponentErrorMessage from "../ComponentErrorMessage/ComponentErrorMessage";
import ErrorReportingService from "../../../services/ErrorReportingService";
import { ErrorBoundary } from "react-error-boundary";
import GraphService from "../../../services/GraphService";
import { UpdateViewAvatarOrgChart, UpdateViewUserOrgChart } from "../../../context/ActionTypes";
import { GraphAvatarData } from "../../../types/GraphTypes/GraphUser";
import searchStyles from "../../molecules/SearchBar/MindbreezeAutoCompleteWithSearch.module.scss";

const backgroundImage: string = require("../../../assets/images/skyline-1.png");

export interface IEmployeeDetailViewProps {}
export interface IEmployeeDetailViewState {
  viewedEmployee: AdwEmployee;
  directReports: AdwEmployee[];
  adminList: AdwEmployee[];
  adwEmployeeListByCriteria: AdwEmployee[];
  viewedUserId: string;
  showMobileEditModal: boolean;
  mobileEditModalDetails: any;
}

const EmployeeDetailView: React.FC<IEmployeeDetailViewProps> = () => {
  document.title = "InSide - Associate Details";
  const [appState,dispatch]: AdwContextType = useContext(AdwContext);

  const [viewedEmployee, setViewedEmployee] = useState(new AdwEmployee());
  const [userPersonalizedEmail, setUserPersonalizedEmail] = useState("");
  const [viewAvatarUser, setViewAvatarUser] = useState(null);
  const [showMobileEditModal, setShowMobileEditModal] = useState(false);
  const [mobileEditModalDetails, setMobileEditModalDetails] = useState({
    url: "",
    title: "",
    contents: <></>,
    subcontents: <></>,
    subheading: <></>,
    show: false,
    onHide: () => {},
  });
  const username: string =
    appState.viewUserDetails || (appState.user && appState.user.nwieID) || "";

  useEffect(() => {
    updateUserInState(username);
  }, [username]);

  useEffect(() => {
    const searchBar = document.getElementById(searchStyles.search);

    if (searchBar) {
      searchBar.style.display = 'none';
    }

    return () => {
      if (searchBar) {
        searchBar.style.display = 'block';
      }
    }
  }, [])


  const updateUserInState = (username: string): void => {
    if (username) {
      if (AuthService.hasActiveSession()) {
        CallupService.getEmployeeByShortName(username).then((adwEmployee) => {
          // If the callup API is failing, default users will have fromError set. Throw error so
          //that the callup error message is shown via the callup error boundary
          if (adwEmployee.fromError) {
            throw new Error(`Failed to get employee ${username} from Callup`);
          }
          setViewedEmployee(adwEmployee);
          if (appState.user && adwEmployee) {
            GraphService.getSelectedOrgChartUser(adwEmployee.shortName).then((viewAvatarUser : GraphAvatarData) => {
              setUserPersonalizedEmail(viewAvatarUser.userPrincipalName);
              dispatch(UpdateViewUserOrgChart(viewAvatarUser.userPrincipalName));

              setViewAvatarUser(viewAvatarUser);
              dispatch(UpdateViewAvatarOrgChart(viewAvatarUser));
            })
          }
        });
      } else {
        setTimeout(() => {
          updateUserInState(username);
        }, 500);
      }
    }
  };

  const editMobileModal = (setShow: boolean): void => {
    setShowMobileEditModal(setShow);
    if (setShow && window.innerWidth <= 1024) {
      window.scrollTo(0, 0);
    }
  };

  const setMobileEditModal = (data: EditModalType, onHide: any): void => {
    setMobileEditModalDetails({
      url: data.url,
      title: data.title,
      contents: data.contents,
      subcontents: data.subcontents,
      subheading: data.subheading,
      onHide: onHide,
      show: mobileEditModalDetails.show,
    });
  };

  return (
    <div
      className={styles.employeeDetailViewContainer}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {showMobileEditModal && (
          <EmployeeDetailModal
          url={mobileEditModalDetails.url}
          title={mobileEditModalDetails.title}
          contents={mobileEditModalDetails.contents}
          subheading={mobileEditModalDetails.subheading}
          subcontent={mobileEditModalDetails.subcontents}
          show={showMobileEditModal}
          onHide={mobileEditModalDetails.onHide}
        />
      ) }

      <div className={styles.contactInfo} id={"ContactInfoWrapper"}>
        <ContactInfo
          adwEmployee={viewedEmployee}
          mobileEditModal={editMobileModal}
          setMobileEditModal={setMobileEditModal}
          userPersonalizedEmail={userPersonalizedEmail}
          avatarUser={viewAvatarUser}
        />
      </div>
      <div className={styles.hrLocation} id={"hrLocation"}>
        <HrLocation adwEmployee={viewedEmployee} />
      </div>
      <div className={styles.orgchart} id={"orgChartWrapper"}>
        <OrgChart avatarUser={viewAvatarUser} userPersonalizedEmail={userPersonalizedEmail} />
      </div>
      <ErrorBoundary
        fallback={
          <ComponentErrorMessage
            parentClass={`${styles.aboutMe} ${styles.aboutMeHeading}`}
            heading="About me"
          />
        }
        onError={(error,info)=>{ 
          ErrorReportingService.reportErrorWithMessage(
          error.toString(),
          "ErrorBoundary.tsx -> CallupErrorMessage -> AboutMe",
          appState.user.nwieID
        )}}>
        <div className={styles.aboutMe} id={"aboutMeWrapper"}>
          <AboutMe
            adwEmployee={viewedEmployee}
            userPersonalizedEmail={userPersonalizedEmail}
            mobileEditModal={editMobileModal}
            setMobileEditModal={setMobileEditModal}
          />
        </div>
      </ErrorBoundary>
    </div>
  );
};
export default EmployeeDetailView;

import { SpFooterLogo } from "./SpFooterLogo";

class AdwFooterLogo {
  public id: number;
  public AlternativeText: string;
  public ImagePath: string; // set From the service, not in SP list
  public LinkPath: string;
  public WindowTarget: string;
  public DisplayOrder: number;
  public DisplayEnabled: boolean;

  constructor(spFooterLogo?: SpFooterLogo) {
    if (spFooterLogo) {
      this.id = spFooterLogo.fields.id;
      this.AlternativeText = spFooterLogo.fields.AlternativeText;
      this.LinkPath = spFooterLogo.fields.LinkPath;
      this.WindowTarget = spFooterLogo.fields.WindowTarget;
      this.DisplayOrder = spFooterLogo.fields.DisplayOrder;
      this.DisplayEnabled = spFooterLogo.fields.DisplayEnabled;
      this.ImagePath = spFooterLogo.fields.ImagePath ? spFooterLogo.fields.ImagePath : "";
    }
  }
}

export default AdwFooterLogo;

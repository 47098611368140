import React, { useLayoutEffect } from "react";
import AuthService from "../../../services/AuthService";
import AmazonWebService from "../../../services/AmazonWebService";

export interface IImageProps {
  id: string;
  src: string;
  className?: string;
  imageClasses?: string;
  onMouseOver?: any;
  onMouseOut?: any;
  onClick?: () => void;
  onError?:any;
  children?:any;
}

const AwsS3BackgroundImage: React.FC<IImageProps> = ({ id,src,imageClasses,className,onError,children, ...otherProps }) => {
  let hasActiveMsalSession = AuthService.hasActiveSession();

  const getImage = async(src:string)=>{
    if(hasActiveMsalSession){
      let imageUrl = await AmazonWebService.getImageFromS3Bucket(src);
      const imgElement = document.getElementById(id) as HTMLDivElement;
      if (imgElement) {
        imgElement.style.background = `url(${imageUrl})`;
      }
    }
  }

  useLayoutEffect( ()=>{
    getImage(src);
  },[hasActiveMsalSession,src]);

  return <div {...otherProps} className={className} id={id}>
    {children}
  </div>;
};

export default AwsS3BackgroundImage;
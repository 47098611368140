import {JwtPayload} from "jsonwebtoken";
import moment from "moment";
import QueryParamUtils from "../utils/QueryParamUtils";
import EnvironmentService from "./EnvironmentService";
import ErrorReportingService from "./ErrorReportingService";
import { getIdTokenFromAzure, getIdTokenFromAzureWithForceRefresh } from "./MsalAuthService";
import jwt_decode from 'jwt-decode';
const crypto = window.crypto;
export interface AzureKeyResponse {
  keys: AzureKey[];
}

export interface AzureKey {
  kid: string;
  x5c: string[];
}

export interface AccessTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
  id_token: string;
}

export interface AccessTokenMessage {
  id_token?: string;
  access_token?: string;
  token_type?: string;
  scope?: string;
  expires_at?: string;
  error?: string;
  jwt?: string;
}

export const NONCE: string = "nw.apigee.nonce";
export const STATE: string = "nw.apigee.state";
export const AZURE_PUBLIC_KEY: string = "nw.apigee.publickey";

export const DO_AUTH: string = "doauth";
export const REFRESH_IFRAME_ID = "inside-auth-refresh";

export type Auth = {
  access_token: string;
  token_type: string;
  expires_at: moment.Moment;
  scope: string;
  error: string;
  id_token: string;
  jwt: string;
}

export default class AuthService {

  public static ApigeeAuth: Auth = {
    access_token: "",
    token_type: "",
    expires_at: null,
    scope: "",
    error: "",
    id_token: "",
    jwt: ""
  }

  public static getState(): string {
    return sessionStorage.getItem(STATE) || QueryParamUtils.getIframeSrcParameter("state");
  }

  public static setState(state: string): void {
    sessionStorage.setItem(STATE, state);
  }

  public static getNonce(): string {
    return sessionStorage.getItem(NONCE) || QueryParamUtils.getIframeSrcParameter("nonce");
  }

  public static setNonce(nonce: string): void {
    sessionStorage.setItem(NONCE, nonce);
  }

  public static readonly handleVisibilityChange = () => {
    const authIframe: HTMLIFrameElement = document.getElementById(REFRESH_IFRAME_ID) as HTMLIFrameElement;
    if (authIframe) {
      authIframe.contentWindow.postMessage({
        refreshSession: true
      }, window.location.origin);
    }
  }

  /** Get Authorization header for request to Apigee */
  public static getAuthorizationHeader(): string {
    return `${AuthService.ApigeeAuth.token_type} ${AuthService.ApigeeAuth.access_token}`;
  }

  /** Get Azure authentication URL, including redirect back to ADW */
  public static getAzureAuthUrl(): string {
    const state: string = AuthService.getSecureRandomString(12)
    const nonce: string = AuthService.getSecureRandomString(12);
    AuthService.setNonce(nonce);
    AuthService.setState(state);
    return `https://login.microsoftonline.com/${EnvironmentService.getAzureTenantId()}/oauth2/authorize?client_id=${EnvironmentService.getAzureClientId()}&response_type=id_token&redirect_uri=${encodeURIComponent(
      `${window.location.origin}/?${DO_AUTH}=true`.toLowerCase(),
    )}&scope=openid&response_mode=fragment&state=${state}&nonce=${nonce}`;
  }

  /**
   * Returns true if ADW has an active access_token, false otherwise
   */
  public static hasActiveSession(): boolean {
    return !!AuthService.ApigeeAuth.expires_at && AuthService.ApigeeAuth.expires_at.isAfter(moment());
  }

  /**
   * Requests an ID token from Azure, which redirects the app to the current URL
   */
  public static startAuthentication() {
      console.log("AuthService >>> startAuthentication -> Start auth");
      window.open(AuthService.getAzureAuthUrl(), "_self");
  }

  /**
   * Set up management of an authentication session
   * @param jwt JWT for creating a new active session if necessary
   */
  public static manageAuthentication(jwt?: string): void {
    localStorage.setItem('start_time', moment().toLocaleString());
    if (jwt) {
      AuthService.requestAccessToken(jwt, 0).then(AuthService.setupTokenRefresh);
    } else {
      AuthService.setupTokenRefresh();
    }
  }

  private static returnResponseData(response:any){
    if (!response.ok) {
      ErrorReportingService.reportErrorWithResponse(
        response,
        "AuthService.ts -> requestAccessToken",
        `Attempted to retrieve: ${response.url}`
      );
      return response;
    }
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("AuthService >>> requestAccessToken : Got response other than 200 : " + response.status);
    }
  }

  private static tryRequestingAccessTokenAgain(numberOfTries:number,idToken:string){
          // Unable to retrieve a new access token, retry in ten second
          if (numberOfTries < 5) {
            setTimeout(() => {
              AuthService.requestAccessToken(idToken, numberOfTries + 1);
            }, 10000);
          }
  }

  /**
   * Retrieve a new access token from EUA
   * @param idToken JWT to use for requesting an access token
   */
  private static async requestAccessToken(idToken: string, numberOfTries: number): Promise<void> {
    if (idToken) {
          return fetch(`${EnvironmentService.getApigeeConfig().domain}/security-processing/enterprise-user-auth/v2/token.oauth2`, {
            method: "post",
            headers: {
              "Content-Type": `application/x-www-form-urlencoded`,
            },
            body: AuthService.getAccessTokenRequestBody(idToken),
          })
            .then(response => {
             return AuthService.returnResponseData(response)
            })
            .then((response: AccessTokenResponse) => {
              const expiresAt = moment().add(response.expires_in, "seconds"); // ttl;
              const accessTokenMsg: AccessTokenMessage = {
                access_token: response.access_token,
                expires_at: expiresAt.toISOString(),
                token_type: response.token_type,
                scope: response.scope,
                id_token: idToken.substring(0, 10),
                error: "",
                jwt: idToken
              };
              
              AuthService.ApigeeAuth.access_token = response.access_token;
              AuthService.ApigeeAuth.expires_at = expiresAt;
              AuthService.ApigeeAuth.token_type = response.token_type;
              AuthService.ApigeeAuth.scope = response.scope;
              AuthService.ApigeeAuth.jwt = idToken;
              window.parent.postMessage(accessTokenMsg, window.location.origin);
              //jwt time
              localStorage.setItem('end_time', moment().toLocaleString());
            })
            .catch(error => {
              AuthService.ApigeeAuth.error = JSON.stringify(error);
              const accessTokenMsg: AccessTokenMessage = {
                id_token: idToken.substring(0, 10),
                error: `ACCESS TOKEN ERROR: ${error}`,
              };

              window.parent.postMessage(accessTokenMsg, window.location.origin);
              AuthService.tryRequestingAccessTokenAgain(numberOfTries,idToken);
              localStorage.setItem('end_time', moment().toLocaleString());
            })
            .finally(function () {
              let start: string = localStorage.getItem('start_time') || 'na';
              let end: string = localStorage.getItem('end_time') || 'na';
              let user: string = localStorage.getItem("currentUser");
              if (user !== "" && ((start !== "na") || (end !== "na"))) {
                ErrorReportingService.reportLogsWithMessage("Sending JWT logs", `AuthService.ts -> requestAccessToken -> Handling JWT Logs`, `JWTStartTime : ${start}  JWTEndTime: ${end}`);
              }
            });
    }
    return Promise.reject();
  }

  /**
   * Set up a timeout for refreshing the token
   */
  private static setupTokenRefresh() {
    const nowPlusBuffer = moment().add(60, "seconds");
   if (AuthService.ApigeeAuth.expires_at.isBefore(nowPlusBuffer)) { //If this expires within the next minute
      AuthService.startAuthentication();
    } else { //Set timer for ~1 minute before
      setTimeout(AuthService.startAuthentication, moment.duration(AuthService.ApigeeAuth.expires_at.diff(nowPlusBuffer)).asMilliseconds());
    }
  }

  /**
   * Get the body of the request for retrieving a new access token
   * @param jwt JWT to use in request body
   */
  private static getAccessTokenRequestBody(jwt: string): string {
    return [
      AuthService.encodeKeyValuePair("grant_type", "urn:ietf:paramsoap:oauth:grant-type:jwt-bearer"),
      AuthService.encodeKeyValuePair("assertion", jwt),
      AuthService.encodeKeyValuePair("client_id", EnvironmentService.getApigeeConfig().clientId),
      AuthService.encodeKeyValuePair("scope", "openid"),
      AuthService.encodeKeyValuePair("realm", "employee"),
      AuthService.encodeKeyValuePair("auth_method", "azure-jwt"),
    ].join("&");
  }

  /** Encode key value pair for use in form body */
  private static encodeKeyValuePair(key: string, value: string): string {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }

  /** Get random string */
  public static getSecureRandomString(length) {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const randomValues = new Uint32Array(length);
    crypto.getRandomValues(randomValues);
  
    let result = '';
    for (let i = 0; i < length; i++) {
      result += charset[randomValues[i] % charset.length];
    }
    return result;
  }

  /** Get Authorization jwt token for request to Mindbreeze */
  public static getAuthorizationToken(): any {
    return getIdTokenFromAzure().then(token => {
      //Below is a workaround for open PDF preview: set idToken to sessionStorage for NewSearch.
      //Please update below code once the long term solution is available.
      if (token) {
        sessionStorage.setItem("idToken.for.search", 'Bearer '.concat(token));
        return `Bearer ${token}`;
      }
      return false;
    })
  }

  /** Get a new Authorization jwt token for request to Mindbreeze */
  public static getMindbreezeHeadersWithForceRefresh(): any {
    return getIdTokenFromAzureWithForceRefresh().then(token => {
      //Below is a workaround for open PDF preview: set idToken to sessionStorage for NewSearch.
      //Please update below code once the long term solution is available.
      if (token) {
        sessionStorage.setItem("idToken.for.search", 'Bearer '.concat(token));
        return `Bearer ${token}`;
      }
      return false;
    })
  }

  /**
   *  Decode the JWT id token, check id token's expiration time.
   *  Return true if it is current, otherwise, false.
   */
  public static isTokenCurrent(token: string): boolean {
    let decodedToken = jwt_decode<JwtPayload>(token);
    let currentDate = new Date();
    let result = true;

    // Check token's expiration time
    if (currentDate.getTime() > (decodedToken.exp * 1000)) {
      console.log("AuthService >>> isTokenCurrent : Token expired.");
      result = false;
    }

    return result;
  }
}

import React, { useContext, useEffect, useRef, useState } from "react";
import { AdwContext } from "../../../context/AdwContext";
import styles from './Announcements.module.scss'
import Announcement from "../../molecules/Announcement/Announcement";
import AdwAnnouncement from "../../../types/AnnouncementsTypes/AdwAnnouncement";

const Alert = require('../../../assets/images/Alert.svg').default;

interface IAnnouncementsProps {
  announcementsGrid: string;
}

const Announcements = ({announcementsGrid}: IAnnouncementsProps) => {

  const [appState] = useContext(AdwContext);
  const [announcements, setAnnouncements] = useState<AdwAnnouncement[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLHeadingElement>(null);
  const screenWidth = useRef(window.innerWidth);
 
  useEffect(() => {
    let newAnnouncements: AdwAnnouncement[] = [];

      if (appState.user) {
        if (appState.user?.dismissedAnnouncements) {
          const dismissedIDs = appState.user?.dismissedAnnouncements.split(',');
          newAnnouncements = appState.lists.announcements?.filter(announcement => {
            return !dismissedIDs.includes(announcement.id.toString());
          })
        } else {
          newAnnouncements = [...appState.lists.announcements];
        }
        newAnnouncements.sort((a) => {
          if (a.pinned) return -1;
        })
      }

      setAnnouncements(newAnnouncements);
  }, [appState.user, appState.user?.dismissedAnnouncements])

  useEffect(() => {
    const newAnnouncements: AdwAnnouncement[] = [...announcements];
    const maxHeight = containerRef.current.clientHeight - headerRef.current.clientHeight;
    
    /*
    We don't know if the announcements will be one line or more,
    so we get as many as we can without overflowing the container
    */
    if (listRef.current?.clientHeight > maxHeight) {
      newAnnouncements.pop();
      setAnnouncements(newAnnouncements);
    }
  }, [announcements])

  useEffect(() => {
    const onResize = () => {
      const maxHeight = containerRef.current.clientHeight - headerRef.current.clientHeight;

      if (
        screenWidth.current < window.innerWidth
        && window.innerWidth > 1024
        && listRef.current?.clientHeight > maxHeight
      ) {
        setAnnouncements(prev => prev.slice(0, -1))
      }
    }

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [])

  return (
    <div className={`${styles.container} ${announcementsGrid}`} ref={containerRef}>
        <h2 className={styles.header} ref={headerRef}>
          Announcements
        </h2>
        {announcements.length > 0 ? (
          <div ref={listRef}>
            {
              announcements.map((announcement) => (
                <Announcement
                  key={announcement.id}
                  id={announcement.id}
                  announcementText={announcement.announcementText}
                  pinned={announcement.pinned}
                  link={announcement.link}
                />
              ))
            }
          </div>
          ) : (
          <div className={styles.emptyAnnouncements}>
            <img src={Alert} alt='Bell icon' className={styles.bellIcon} />
            <p className={styles.emptyAnnouncementsText}>Looks like you have no announcements left! Check back later.</p>
          </div>
          )
        }
    </div>
  )
}

export default Announcements;
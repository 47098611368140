import React from "react";

const LoadingSpinner = () => (
  <div
    id="loading-spinner"
    style={{
      background: `url(${require("../../../assets/images/spinner.gif")}) top center no-repeat`,
      minHeight: "calc(100vh - 300px)",
      minWidth: "150px",
      margin: "auto",
      marginTop: "35px",
    }}
  />
);

export default LoadingSpinner;

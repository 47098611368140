import React from "react";
import styles from "./OrgChartWidget.module.scss";
import DirectReport from "../DirectReport/DirectReport";
import { GraphAvatarData } from "../../../types/GraphTypes/GraphUser";

export interface IOrgChartWidgetProps {
  orgChart: GraphAvatarData[];
  showOrgChart: boolean;
}

const OrgChartWidget= ({orgChart,showOrgChart}) => {
  return (orgChart && orgChart.map((employee: GraphAvatarData, index: number) => (
      <li
        className={styles.line}
        id={`employee-${index}`}
        key={employee.id}
      >
        <DirectReport avatarData={employee} showOrgChart={showOrgChart} />
      </li>
  )));
};

export default OrgChartWidget;

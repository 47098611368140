import React, {useEffect, useContext, useState} from "react";
import styles from "./AAECountdownClock.module.scss";
import moment from "moment";
import AAECountdownInfo from "../../../types/AAECountdownClock";
import EnvironmentService from "../../../services/EnvironmentService";
import { AdwContext ,AdwContextType } from "../../../context/AdwContext";
import AwsS3BackgroundImage from "../../atoms/AwsS3BackgroundImage/AwsS3BackgroundImage";

export interface ICountdownClockState {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  backgroundImage: string;
}

const AAECountdownClock: React.FC<{}> = () => {
  const [appState]: AdwContextType = useContext(AdwContext);
  const aaeCountDown: AAECountdownInfo = appState && appState.lists && appState.lists.aaeCountdownClock && appState.lists.aaeCountdownClock[0];

  const [duration, setDuration] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [, setImg]: any = useState();

  useEffect(() => {
    const countdownInterval = aaeCountDown.fields.EventDateAndTime && setInterval(() => {
      setDuration(getDaysHoursMinutesSeconds(moment(aaeCountDown.fields.EventDateAndTime).diff(moment(), "seconds")));
    }, 1000);
    return () => { clearInterval(countdownInterval); };
  }, [aaeCountDown]);

  useEffect(() => {
    if (aaeCountDown && aaeCountDown.fields && aaeCountDown.fields.ImagePath) {
        setImg(`${EnvironmentService.getCloudfrontUrl()}${encodeURIComponent(aaeCountDown.fields.ImagePath)}`);
    }
  }, [aaeCountDown, aaeCountDown.fields, aaeCountDown.fields.ImagePath])

  const displayWithZeroIfLessThanTen = (timeValue: number): string => (timeValue < 10 ? `0${timeValue}` : `${timeValue}`);

  const getDaysHoursMinutesSeconds = (durationInSeconds: number): any => {
    if (durationInSeconds < 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    } else {
      const days: number = Math.floor(durationInSeconds / (24 * 3600));
      const hours: number = Math.floor((durationInSeconds - days * (24 * 3600)) / 3600);
      const minutes: number = Math.floor((durationInSeconds - days * (24 * 3600) - hours * 3600) / 60);
      const seconds: number = Math.floor(durationInSeconds - days * (24 * 3600) - hours * 3600 - minutes * 60);
      return { days, hours, minutes, seconds };
    }
  };

  return (
    <AwsS3BackgroundImage src={aaeCountDown.fields.ImagePath}
        id={`countdownContainer-aws-img-main-background`}
        className={styles.countdownContainer} >
      <div className={styles.clockContainer}>
        <div>{displayWithZeroIfLessThanTen(duration.days)}</div>
        <div>{displayWithZeroIfLessThanTen(duration.hours)}</div>
        <div>{displayWithZeroIfLessThanTen(duration.minutes)}</div>
        <div>{displayWithZeroIfLessThanTen(duration.seconds)}</div>
      </div>
     </AwsS3BackgroundImage>
  );
};

export default AAECountdownClock;

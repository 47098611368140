import React from "react";
import InsideDataService from "../services/InsideDataService";
import AAECountdownInfo from '../types/AAECountdownClock';
import AdwAlert from '../types/AlertTypes/AdwAlert';
import AdwAtoZView from '../types/AtoZViewTypes/AdwAtoZView';
import AdwCampaignBanner from '../types/CampaignBannerTypes/AdwCampaignBanner';
import AdwCommonTool from '../types/CommonToolsTypes/AdwCommonTool';
import AdwNewsStory from '../types/CompanyNewsTypes/AdwNewsStory';
import AdwFeaturedContent from '../types/FeaturedContentTypes/AdwFeaturedContent';
import AdwFooterLogo from '../types/FooterTypes/AdwFooterLogo';
import { InsideUserData } from "../types/InsideUserData";
import { LocationName } from '../types/Locations/LocationName';
import { MailCodeFloor } from '../types/Locations/MailCodeFloor';
import AdwReminder from '../types/ReminderTypes/AdwReminder';
import ActionTypes from "./ActionTypes";
import { GraphAvatarData, GraphUser } from "../types/GraphTypes/GraphUser";
import moment from "moment";
import AdwFooterMenuItem from "../types/FooterTypes/AdwFooterMenuItem";
import { MegaMenuCategory } from "../types/MegaMenuTypes/MegaMenuCategory";
import { AdwContext } from "./AdwContext";
import AdwHrConnectLink from "../types/FooterTypes/AdwHrConnectLink";
import { AdwHrConnectSwitch } from "../types/HRConnectTypes/AdwHrConnectSwitch";
import AdwCalendarEventType from "../types/CalendarTypes/AdwCalendarEventType";
import { AdwFeatureSwitch } from "../types/FeatureSwitchTypes/AdwFeatureSwitch";
import AdwFcEvent from "../types/CalendarTypes/AdwFcEvent";
import RequestBody from "../types/SearchTypes/RequestBody";
import AdwPopularTool from "../types/PopularToolsTypes/AdwPopularTool";
import AdwWhatChanged from "../types/WhatChangedTypes/AdwWhatChanged";
import AdwMyTechNotification from "../types/NotificationTypes/AdwMyTechNotification";
import AdwAnnouncement from "../types/AnnouncementsTypes/AdwAnnouncement";

/**
 * The interface representing the global state of the application. If properties are added here,
 * they need to be initialized initialContextState below.
 */
export interface AdwContextState {
  search: SearchContextState;
  user: InsideUserData;
  graphUser: GraphUser;
  viewUserDetails: string;
  viewUserOrgChart: string;
  viewAvatarOrgChart: GraphAvatarData;
  lists: AdwLists;
  isAdmin: boolean;
  hrConnect: HRConnect;
}

/**
 * The interface representing the global search state. If properties are added here, they
 * need to be initialized initialContextState below.
 */
export interface SearchContextState {
  searchRequest: RequestBody;
  query: string;
  searchPage: boolean;
}

export interface AdwLists {
  aaeCountdownClock: AAECountdownInfo[],
  alerts: AdwAlert[],
  myTechNotification: AdwMyTechNotification[],
  aToZView: AdwAtoZView[],
  nextFourEvent:AdwFcEvent[],
  nextFivePayday:AdwFcEvent[],
  fcCompanyEvent:AdwFcEvent[],
  companyEventType: AdwCalendarEventType[],
  campaignBanner: AdwCampaignBanner[],
  commonTools: AdwCommonTool[],
  popularTools: AdwPopularTool[],
  whatChanged: AdwWhatChanged[],
  companyNews: AdwNewsStory[],
  announcements: AdwAnnouncement[],
  featuredContent: AdwFeaturedContent[],
  footerLogos: AdwFooterLogo[],
  footerNavigation: AdwFooterMenuItem[],
  mailCodeFloor: MailCodeFloor[],
  nwLocationName: LocationName[],
  reminders: AdwReminder[],
  siteNavigation: MegaMenuCategory[],
  hrConnectLinks: AdwHrConnectLink[],
  hrConnectSwitches: AdwHrConnectSwitch[]
  featureSwitches: AdwFeatureSwitch[]
  aaeCountdownClockExpired: boolean,
  alertsExpired: boolean,
  aToZViewExpired: boolean,
  campaignBannerExpired: boolean,
  commonToolsExpired: boolean,
  popularToolsExpired: boolean,
  whatChangedExpired: boolean,
  companyEventExpired: boolean,
  companyEventTypeExpired: boolean,
  companyNewsExpired: boolean,
  featuredContentExpired: boolean,
  footerLogosExpired: boolean,
  footerNavigationExpired: boolean,
  remindersExpired: boolean,
  siteNavigationExpired: boolean,
  hrConnectLinksExpired: boolean,
}

export interface HRConnect {
  yourTime: {
    current: {
      hours: number,
      days: number
    },
    cumulative: {
      hours: number,
      days: number
    }
  },
  myPay: {
    net: number,
    nextPayDay: string
  }
  jobs: {
    openJobs: number,
    activeApps: number
  }
  fow: {
    total: number,
    digital: number,
    lia: number
  },
  benefitElections: {
    dentalCoverage: string,
    dentalPlan: string,
    endDateOE: string,
    enrollmentStatus: string,
    finalizeDateOE: string,
    hsaAmount: string,
    hsaCoverage: string,
    hsaPlan: string,
    medicalCoverage: string,
    medicalPlan: string,
    oeUrl: string,
    shortName: string,
    startDateOE: string,
    visionCoverage: string,
    visionPlan: string
  },
  retirementSavingsElections: {
    employeePct: string,
    retirementPlanID: string,
    retirementPlanName: string
  },
  associateWellbeingSafety: {
    nurseName: string,
    nursePhone: string,
    nurseEmail: string,
    counselorName: string,
    counselorPhone: string
  },
  myHealthProgram: {
    awardEarning: number,
    awardMedia: string,
    awardType: string,
    dob: string,
    emplid: string,
    firstName: string,
    hireDate: string,
    isEnrolled: string,
    lastName: string,
    maxAllowed: number,
    newHireCutoffDate: string,
    progEndDate: string,
    warningDays: string
  }
}

/**
 * Function used for updating the global state of the action.
 * To add a new global state modification action, first add an ActionType and a function which
 * returns the DispatchAction to ActionTypes.ts
 *
 * @param state Global state of the application before the current action is processed
 * @param action Action to process
 */
export const adwContextReducer = (state: AdwContextState, action: DispatchAction): AdwContextState => {
  let searchRequest: RequestBody = Object.assign({}, state.search.searchRequest);

  switch (action.type) {
    case ActionTypes.SEARCH_DO_SEARCH:
      return {
        ...state,
        search: Object.assign(state.search, { searchTime: moment.now(), options: action.options }),
      };
    // Update search query
    case ActionTypes.SEARCH_UPDATE_QUERY:
      searchRequest.searchTerm = action.query;
      searchRequest.searchPage = action.searchPage;
      return {
        ...state,
        search: Object.assign(state.search, { searchRequest, query: action.query, searchPage: action.searchPage }),
      };
      case ActionTypes.SEARCH_DO_PEOPLE_SUGGESTIONS_SEARCH:
      return {
        ...state,
        search: Object.assign(state.search, { suggestionTime: moment.now(), options: action.options, peopleSuggestionResponse: undefined }),
      };
    // Update search response
    case ActionTypes.SEARCH_UPDATE_RESPONSE:
      return {
        ...state,
        search: Object.assign(state.search, { searchResponse: action.searchResponse }),
      };
    case ActionTypes.SEARCH_UPDATE_PEOPLE_SUGGESTIONS:
      return {
        ...state,
        search: Object.assign(state.search, { peopleSuggestionResponse: action.peopleSuggestionResponse }),
      };
    // Update search people results
    case ActionTypes.SEARCH_UPDATE_PEOPLE_RESPONSE:
      return {
        ...state,
        search: Object.assign(state.search, { peopleSearchResponse: action.peopleSearchResponse }),
      };
    // Update search error message
    case ActionTypes.SEARCH_UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        search: Object.assign(state.search, { error: action.error }),
      };
    // Update if search is currently searching
    case ActionTypes.SEARCH_UPDATE_CURRENTLY_SEARCHING:
      return {
        ...state,
        search: Object.assign(state.search, { currentlySearching: action.currentlySearching }),
      };
      // Update last search term
    case ActionTypes.SEARCH_UPDATE_LAST_SEARCH_TERM:
      return { ...state, search: Object.assign(state.search, { lastSearchTerm: action.lastSearchTerm }) };
    // Update which result has the "flag this" modal open
    case ActionTypes.SEARCH_UPDATE_FLAG_MODAL:
      return { ...state, search: Object.assign(state.search, { flagModalIndex: action.flagModalIndex }) }
    // Set or update user preferences
    case ActionTypes.SET_USER_PREFERENCES:
      if (!action.initialLoad) {
        InsideDataService.saveDataToInside(action.user);
      }
      return {
        ...state,
        user: action.user,
      };
    case ActionTypes.SET_USER_DETAILS_NWIE:
      return {
        ...state,
        viewUserDetails: action.nwie,
      };
      case ActionTypes.SET_USER_ORG_CHART:
        return {
          ...state,
          viewUserOrgChart: action.viewUserOrgChart,
        };
    case ActionTypes.SET_AVATAR_ORG_CHART:
      return {
        ...state,
        viewAvatarOrgChart: action.viewAvatarOrgChart,
      };
    case ActionTypes.SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.isAdmin
      }
      case ActionTypes.SET_GRAPH_USER:
      return {
        ...state,
        graphUser: action.graphUser
      }
    case ActionTypes.SET_SP_LIST_CONTENTS:
      switch (action.list.toLowerCase()) {
        case "aae countdown clock":
          return {
            ...state,
            lists: Object.assign(state.lists, { aaeCountdownClock: action.contents })
          }
        case "alerts":
          return {
            ...state,
            lists: Object.assign(state.lists, { alerts: action.contents })
          }
          case "mytech_user_notifications_inside_data":
            return {
              ...state,
              lists: Object.assign(state.lists, { myTechNotification: action.contents })
            }
        case "atoz":
          return {
            ...state,
            lists: Object.assign(state.lists, { aToZView: action.contents })
          }
        case "company event":
          return {
            ...state,
            lists: Object.assign(state.lists, { companyEvent: action.contents })
          }
        case "next four event":
          return {
            ...state,
            lists: Object.assign(state.lists, { nextFourEvent: action.contents })
          }
        case "next five payday":
          return {
            ...state,
            lists: Object.assign(state.lists, { nextFivePayday: action.contents })
          }
        case "company event type":
          return {
            ...state,
            lists: Object.assign(state.lists, { companyEventType: action.contents })
          }
        case "campaign banner":
          return {
            ...state,
            lists: Object.assign(state.lists, { campaignBanner: action.contents })
          }
        case "common tools":
          return {
            ...state,
            lists: Object.assign(state.lists, { commonTools: action.contents })
          }
        case "popular tools":
          return {
            ...state,
            lists: Object.assign(state.lists, { popularTools: action.contents })
          }
        case "what changed":
          return {
            ...state,
            lists: Object.assign(state.lists, { whatChanged: action.contents })
          }
        case "company news":
          return {
            ...state,
            lists: Object.assign(state.lists, { companyNews: action.contents })
          }
        case "announcements":
          return {
            ...state,
            lists: Object.assign(state.lists, { announcements: action.contents })
          }
        case "featured content":
          return {
            ...state,
            lists: Object.assign(state.lists, { featuredContent: action.contents })
          }
        case "footer logos":
          return {
            ...state,
            lists: Object.assign(state.lists, { footerLogos: action.contents })
          }
        case "footer navigation":
          return {
            ...state,
            lists: Object.assign(state.lists, { footerNavigation: action.contents })
          }
        case "mailcodefloor":
          return {
            ...state,
            lists: Object.assign(state.lists, { mailCodeFloor: action.contents })
          }
        case "nwlocationname":
          return {
            ...state,
            lists: Object.assign(state.lists, { nwLocationName: action.contents })
          }
        case "reminders":
          return {
            ...state,
            lists: Object.assign(state.lists, { reminders: action.contents })
          }
        case "site navigation":
          return {
            ...state,
            lists: Object.assign(state.lists, { siteNavigation: action.contents })
          }
        case "hrconnect links":
          return {
            ...state,
            lists: Object.assign(state.lists, { hrConnectLinks: action.contents })
          }
        case "hrconnect switches":
          return {
            ...state,
            lists: Object.assign(state.lists, { hrConnectSwitches: action.contents })
          }
        case "feature switches":
          return {
            ...state,
            lists: Object.assign(state.lists, { featureSwitches: action.contents })
          }
      }
      break;
    case ActionTypes.SET_HR_TIME_OFF_CURRENT:
      return {
        ...state,
        hrConnect: Object.assign(state.hrConnect, Object.assign(state.hrConnect.yourTime, {
          current: {
            hours: action.hours, days: action.days
          }
        })),
      };
    case ActionTypes.SET_HR_TIME_OFF_CUMULATIVE:
      return {
        ...state,
        hrConnect: Object.assign(state.hrConnect, Object.assign(state.hrConnect.yourTime, {
          cumulative: {
            hours: action.hours, days: action.days
          }
        })),
      };
    case ActionTypes.SET_NET_PAY:
      return {
        ...state,
        hrConnect: Object.assign(state.hrConnect, Object.assign(state.hrConnect.myPay, {
          net: action.netPay,
        })),
      };
    case ActionTypes.SET_NEXT_PAY_DAY:
      return {
        ...state,
        hrConnect: Object.assign(state.hrConnect, Object.assign(state.hrConnect.myPay, {
          nextPayDay: action.nextPayDay,
        }))
      };
    case ActionTypes.SET_HR_OPEN_JOBS:
      return {
        ...state,
        hrConnect: Object.assign(state.hrConnect, Object.assign(state.hrConnect.jobs, {
          openJobs: action.jobs
        })),
      };
    case ActionTypes.SET_HR_ACTIVE_APPS:
      return {
        ...state,
        hrConnect: Object.assign(state.hrConnect, Object.assign(state.hrConnect.jobs, {
          activeApps: action.apps
        })),
      };
    case ActionTypes.SET_HR_FOW_TOTAL:
      return {
        ...state,
        hrConnect: Object.assign(state.hrConnect, Object.assign(state.hrConnect.fow, {
          total: action.total
        })),
      };
    case ActionTypes.SET_HR_FOW_DIGITAL:
      return {
        ...state,
        hrConnect: Object.assign(state.hrConnect, Object.assign(state.hrConnect.fow, {
          digital: action.digital
        })),
      };
    case ActionTypes.SET_HR_FOW_LIA:
      return {
        ...state,
        hrConnect: Object.assign(state.hrConnect, Object.assign(state.hrConnect.fow, {
          lia: action.lia
        })),
      };
    case ActionTypes.SET_BENEFIT_ELECTIONS:
      return {
        ...state,
        hrConnect: Object.assign(state.hrConnect, Object.assign({
          benefitElections: action.benefitElections
        })),
      };
    case ActionTypes.SET_MY_HEALTH_PROGRAM:
      return {
        ...state,
        hrConnect: Object.assign(state.hrConnect, Object.assign({
          myHealthProgram: action.myHealthProgram
        })),
      };
    case ActionTypes.SET_HR_RETIREMENT_EMPLOYEEPCT:
      return {
        ...state,
        hrConnect: Object.assign(state.hrConnect, Object.assign(state.hrConnect.retirementSavingsElections, {
          employeePct: action.employeePct
        })),
      };
    case ActionTypes.SET_HR_AWS_COUNSELOR:
      return {
        ...state,
        hrConnect: Object.assign(state.hrConnect, Object.assign(state.hrConnect.associateWellbeingSafety, {
          counselorName: action.counselorName,
          counselorPhone: action.counselorPhone
        })),
      };
    case ActionTypes.SET_HR_AWS_NURSE:
      return {
        ...state,
        hrConnect: Object.assign(state.hrConnect, Object.assign(state.hrConnect.associateWellbeingSafety, {
          nurseName: action.nurseName,
          nursePhone: action.nursePhone,
          nurseEmail: action.nurseEmail
        })),
      };
    default:
      return state;
  }
};

/*
 * WARNING - The code below configures the global app context and hooks used for managing global app state.
 * This code should not need to be modified outside of app architectural changes.
 * The majority of changes concerning global app state should be made in the code above.
 * Only change this code if you're certain it is absolutely necessary.
 */

/**
 * A type for the actions that are being passed to reducer.
 * ActionTypes are defined in ActionTypes.ts. If you want to create a new action
 * you first need to create a new action type and method that returns a DispatchAction
 */
export type DispatchAction = { type: ActionTypes;[key: string]: any };

/**
 * This interface defines the props for the provider component. When using the provider
 * it will require the reducer used to update application state and the initial application
 * state.
 */
export interface AdwContextProviderProps {
  reducer: (state: AdwContextState, action: DispatchAction) => AdwContextState;
  initialState: AdwContextState;
  children:React.ReactNode;
}

/**
 *  AdwContextProvider component requires the reducer and intial state of the context.
 *  From there, all children rendered within the provider will have access to the context.
 *  @param reducer The reducer used to update context
 *  @param initialState The initial application state
 */
export const AdwContextProvider: React.FC<AdwContextProviderProps> = ({ reducer, initialState, children }) => (
  <AdwContext.Provider value={React.useReducer<(state: AdwContextState, action: DispatchAction) => AdwContextState>(reducer, initialState)}>
    {children}
  </AdwContext.Provider>
);

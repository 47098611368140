export enum Environments {
  DEV = "dev",
  ST = "ST",
  XT = "XT",
  PT = "PT",
  PROD = "prod",
}

export interface ApigeeConfig {
  domain: string;
  clientId: string;
  accountNumber: string;
}

export default class EnvironmentService {

  /** Environment-specific constants */
  private static environment: string;
  private static siteUrl: string;
  private static myTechUrl: string;
  private static apigeeConfig: ApigeeConfig;
  private static cloudfrontUrl: string;
  private static insideUserDataApigeeUrl: string;
  private static awsApigeeUrl: string;
  private static sharepointSiteId: string;
  private static myTechSharepointSiteId: string;
  private static analyticsScript: string;
  private static mindBreezeSearchUrl: string;

  public static getSharepointTenantDomain(): string {
    return "https://onyourside.sharepoint.com";
  }

  public static getAzureClientId(): string {
    return "a8d35c57-0480-42de-8546-0194b56bb9ff";
  }

  public static getAzureTenantId(): string {
    return "22140e4c-d390-45c2-b297-a26c516dc461";
  }

  public static getFeedbackUrl(): string {
    return `${EnvironmentService.getSharepointTenantDomain()}/sites/CorporateInternalCommunications/Lists/InSide Feedback/NewForm.aspx?Source=${EnvironmentService.getSharepointTenantDomain()}/sites/CorporateInternalCommunications/Lists/InSide%20Feedback/AllItems.aspx?viewpath=%2Fsites%2FCorporateInternalCommunications%2FLists%2FInSide%20Feedback%2FAllItems.aspx&ContentTypeId=0x010091B2E8F03F41FA4AB953A862C18EE715&RootFolder`;
  }

  public static getNewAtoZFeedbackUrl(): string {
    return "https://forms.office.com/r/sKXXMZPaVY";
  }

  public static getAtoZFeedbackUrl(): string {
    return `${EnvironmentService.getSharepointTenantDomain()}/sites/CorporateInternalCommunications/Lists/Sites%20AZ%20Feedback/NewForm.aspx?Source=https%3A%2F%2Fonyourside%2Esharepoint%2Ecom%2Fsites%2FCorporateInternalCommunications%2FLists%2FSites%2520AZ%2520Feedback%2FAllItems%2Easpx%3Fviewid%3D476ec9dc%252Deb9a%252D4b0c%252Da093%252D8a0defa838a0&ContentTypeId=0x0100880EDC03A9EE144FA09374EE35E698550098F05F873CCB0E4D9BD34740A20C70DE&RootFolder=%2Fsites%2FCorporateInternalCommunications%2FLists%2FSites%20AZ%20Feedback`;
  }

  public static getEnvironment(): string {
    if (!EnvironmentService.environment) {
      const url: string = window.location.href.toLowerCase();
      if (url.indexOf("st.apps") > -1) {
        EnvironmentService.environment = Environments.ST;
      }else if (url.indexOf("xt.apps") > -1) {
          EnvironmentService.environment = Environments.XT;
      } else if (url.indexOf("pt.apps") > -1) {
        EnvironmentService.environment = Environments.PT;
      } else if (url.indexOf("inside.nationwide") > -1) {
        EnvironmentService.environment = Environments.PROD;
      } else {
        EnvironmentService.environment = Environments.DEV;
      }
    }
    return EnvironmentService.environment;
  }

  public static getCloudfrontUrl(): string {
    if (!EnvironmentService.cloudfrontUrl) {
      switch (EnvironmentService.getEnvironment()) {
        case Environments.DEV:
        case Environments.ST:
        case Environments.XT:
            EnvironmentService.cloudfrontUrl = `https://test-media-inside.nationwide.com/`;
            break;
        case Environments.PT:
        case Environments.PROD:
          EnvironmentService.cloudfrontUrl = `https://media-inside.nationwide.com/`;
          break;
        default:
         EnvironmentService.cloudfrontUrl = `https://media-inside.nationwide.com/`;
      }
    }
    return EnvironmentService.cloudfrontUrl;
  }

  public static getSharepointSiteUrl(): string {
    if (!EnvironmentService.siteUrl) {
      switch (EnvironmentService.getEnvironment()) {
        case Environments.DEV:
          EnvironmentService.siteUrl = `${EnvironmentService.getSharepointTenantDomain()}/sites/ADW-Dev`;
          break;
        case Environments.ST:
          EnvironmentService.siteUrl = `${EnvironmentService.getSharepointTenantDomain()}/sites/ADW-Test`;
          break;
        case Environments.XT: /* point to ST for now - if we end up utilize XT a lot, we may need to create a new sharepoint for XT */
            EnvironmentService.siteUrl = `${EnvironmentService.getSharepointTenantDomain()}/sites/ADW-Test`;
            break;
        case Environments.PT:
          EnvironmentService.siteUrl = `${EnvironmentService.getSharepointTenantDomain()}/sites/ADW-PT`;
          break;
        case Environments.PROD:
        default:
          EnvironmentService.siteUrl = `${EnvironmentService.getSharepointTenantDomain()}/sites/InSide`;
          break;
      }
    }
    return EnvironmentService.siteUrl;
  }

  public static getMyTechUrl(): string {
    if (!EnvironmentService.myTechUrl) {
      EnvironmentService.myTechUrl = `${EnvironmentService.getSharepointTenantDomain()}/sites/MyTechPortal`;
    }
    return EnvironmentService.myTechUrl;
  }
  // https://graph.microsoft.com/v1.0/sites/onyourside.sharepoint.com,e00effe7-c088-479f-a8ea-8be5c4c9f6dc/lists/MyTech_User_Notifications_Inside_Data/items?select=id&expand=fields(select=Title,notificationData,userGuid)&$filter=fields/userGuid eq '35f74619-db54-42dd-bbd6-2e916e04e98c'


  /**
   * Client IDs are specific to certain environemnts.
   * api-dev.nwie, api-test.nwie = Aesq...
   * api-stage.nationwide, api.nationwide = GDNj...
   */
  public static getApigeeConfig(): ApigeeConfig {
    if (!EnvironmentService.apigeeConfig) {
      switch (EnvironmentService.getEnvironment()) {
        case Environments.DEV:
          EnvironmentService.apigeeConfig = {
            domain: "https://api-dev.nwie.net",
            clientId: "AeqS2Je2TyAKv3FmAPDw65xrCwP8mIRr",
            accountNumber: "359841323734"
          }
          break;
        case Environments.XT:
        case Environments.ST:
          EnvironmentService.apigeeConfig = {
            domain: "https://api-test.nwie.net",
            clientId: "AeqS2Je2TyAKv3FmAPDw65xrCwP8mIRr",
            accountNumber: "359841323734"
          }
          break;
        case Environments.PT:
          EnvironmentService.apigeeConfig = {
            domain: "https://api-stage.nationwide.com",
            clientId: "GDNjDKdgCDBFXnsxG4PLIjCJKz5bsQMA",
            accountNumber: "777238983090"
          }
          break;
        case Environments.PROD:
        default:
          EnvironmentService.apigeeConfig = {
            domain: "https://api.nationwide.com",
            clientId: "GDNjDKdgCDBFXnsxG4PLIjCJKz5bsQMA",
            accountNumber: "777238983090"
          }
      }
    }
    return EnvironmentService.apigeeConfig;
  }

  public static getInsideUserDataServiceUrl(): string {
    if (!EnvironmentService.insideUserDataApigeeUrl) {
      EnvironmentService.insideUserDataApigeeUrl = `${EnvironmentService.getApigeeConfig().domain}/marketing-communications/insideuserdata/v1`;
    }
    return EnvironmentService.insideUserDataApigeeUrl;
  }

  public static getAWSApigeeUrl(): string {
    if (!EnvironmentService.awsApigeeUrl) {
      EnvironmentService.awsApigeeUrl = `${EnvironmentService.getApigeeConfig().domain}/marketing-communications/insideuserdata/v1/marketing-images/insideimages-origin-us-east-2-${EnvironmentService.getApigeeConfig().accountNumber}`;
    }
    return EnvironmentService.awsApigeeUrl;
  }


  public static getSiteId(): string {
    if (!EnvironmentService.sharepointSiteId) {
      switch (EnvironmentService.getEnvironment()) {
        case Environments.DEV:
          EnvironmentService.sharepointSiteId =
            "onyourside.sharepoint.com,af59e05b-c280-4db1-b9c7-f3c0e53e04a9";
          break;
        case Environments.XT: /* point to ST for now - if we end up utilize XT a lot, we may need to create a new sharepoint for XT */
        case Environments.ST:
          EnvironmentService.sharepointSiteId =
            "onyourside.sharepoint.com,26e07558-3ec0-42a1-951b-d5ef4fb75cdd";
          break;
        case Environments.PT:
          EnvironmentService.sharepointSiteId =
            "onyourside.sharepoint.com,36c05b88-0e77-466d-b539-07e4f7a57492";
          break;
        case Environments.PROD:
        default:
          EnvironmentService.sharepointSiteId =
            "onyourside.sharepoint.com,7fd944f5-608f-407f-b2ea-2296cb4cd95d";
          break;
      }
    }
    return EnvironmentService.sharepointSiteId;
  }

  public static getMyTechSiteId(): string {
    if (!EnvironmentService.myTechSharepointSiteId) {
      EnvironmentService.myTechSharepointSiteId =
      "onyourside.sharepoint.com,e00effe7-c088-479f-a8ea-8be5c4c9f6dc";
    }
    return EnvironmentService.myTechSharepointSiteId;
  }

  public static getAnalyticsScript(): string {
    if (!EnvironmentService.analyticsScript) {
      if (EnvironmentService.getEnvironment() === Environments.PROD) {
        EnvironmentService.analyticsScript =
          "//tags.nationwide.com/Bootstrap.js";
      } else {
        EnvironmentService.analyticsScript =
          "//tags.nationwide.com/test/Bootstrap.js";
      }
    }
    return EnvironmentService.analyticsScript;
  }

  public static getOIDCRefreshUrl(): string {
    return `${window.location.origin}/refresh.html`;
  }

  public static showHRConnect(employeeType: string): boolean {
    const env: string = EnvironmentService.getEnvironment();
    return env === Environments.DEV ||
      env === Environments.ST ||
      env === Environments.XT ||
      employeeType === 'E'
  }

  public static getMindBreezeSearchUrl(): string {
    if (!EnvironmentService.mindBreezeSearchUrl) {
      switch (EnvironmentService.getEnvironment()) {
        case Environments.DEV:
          EnvironmentService.mindBreezeSearchUrl =
          "https://nationwide-dev.mindbreeze.com";   
          break;
        case Environments.XT:
        case Environments.ST:
          EnvironmentService.mindBreezeSearchUrl =
            "https://nationwide-acc.mindbreeze.com";
          break;
        case Environments.PT:
          EnvironmentService.mindBreezeSearchUrl =
            "https://nationwide.mindbreeze.com";
          break;
        case Environments.PROD:
        default:
          EnvironmentService.mindBreezeSearchUrl = "https://nationwide.mindbreeze.com";
          break;
      }
    }
    return EnvironmentService.mindBreezeSearchUrl;
  }
}

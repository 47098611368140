"use client";
import React, { useContext } from "react";
import shave from "shave";
import AdwNewsStory from "../../../types/CompanyNewsTypes/AdwNewsStory";
import Link from "../../atoms/Link/Link";
import { handleImageLoadError } from "../FeaturedStory/FeaturedStory";
import styles from "./StorySnapshot.module.scss";
import { AdwContext } from "../../../context/AdwContext";
import ContentTime from "../../atoms/ContentTime/ContentTime";
import AwsS3Image from "../../atoms/AwsS3Image/AwsS3Image";

export interface IStorySnapshotProps {
  story: AdwNewsStory;
  index: number;
}

const StorySnapshot: React.FC<IStorySnapshotProps> = ({
  story,
  index,
}) => {
  const [appState]=useContext(AdwContext);
  const nwieID=appState && appState?.user && appState?.user?.nwieID;

  const headingClasses: string =`${styles.heading}`;
  const storyClasses: string = `${styles.story}`;
  const truncateTitles = (): void => {
    shave(`#companyNews-story-title-${index}`, 60);
  };

  return (
    <>
      {story.storyLink ? (
        <div className={storyClasses}>
          <Link
            url={story.storyLink}
            target={story.windowTarget}
            linkClasses={styles.imgLink}
            id={`companyNews-story-img-${index}`}
            data-testid={`companyNews-story-img-${index}`}
          >
            <AwsS3Image src={story.imagePath} id={`companyNews-story-aws-img-${index}`} 
              alt={story.imageAlternativeText}
              className={styles.thumbnail}
              onError={(e)=>handleImageLoadError(e,nwieID)}/>
          </Link>
          <div className={styles.storyInfo}>
            <Link
              url={story.storyLink}
              target={story.windowTarget}
              linkClasses={styles.link}
              id={`companyNews-story-link-${index}`}
              data-testid={`companyNews-story-link-${index}`}
            >
              <h3 title={story.title}
                className={headingClasses}
                id={`companyNews-story-title-${index}`}
              >
                {story.title}
              </h3>
            </Link>
            <ContentTime contentAction={story.contentAction} contentTime={story.contentTime}/>
          </div>
        </div>
      ) : (
        <div className={storyClasses}>
          <AwsS3Image src={story.imagePath} id={`companyNews-story-aws-img-${index}`} 
            alt={story.imageAlternativeText}
            className={styles.thumbnail}
            onError={(e)=>handleImageLoadError(e,nwieID)}/>
          <div className={styles.storyInfo}>
            <h3
              className={headingClasses}
              id={`companyNews-story-title-${index}`}
            >
              {story.title}
            </h3>
            <ContentTime contentAction={story.contentAction} contentTime={story.contentTime}/>
          </div>
        </div>
      )}
    </>
  );
};

export default StorySnapshot;

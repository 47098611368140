import React, { useContext } from "react";
import AdwCampaignBanner from "../../../types/CampaignBannerTypes/AdwCampaignBanner";
import styles from "./CampaignBanner.module.scss";
import EnvironmentService from "../../../services/EnvironmentService";
import { AdwContextType, AdwContext } from "../../../context/AdwContext";
import AwsS3Image from "../../atoms/AwsS3Image/AwsS3Image";


const CampaignBanner: React.FC<{}> = () => {
  const [appState]: AdwContextType = useContext(AdwContext);
  const campaignBanner = appState && appState.lists && appState.lists.campaignBanner && appState.lists.campaignBanner.length ? appState.lists.campaignBanner[0] : null;
  const [, setImg] = React.useState(""); 
  React.useEffect(() => {
    if (campaignBanner && campaignBanner.ImagePath) {
      setImg(`${EnvironmentService.getCloudfrontUrl()}${campaignBanner.ImagePath}`);
    }
  }, [campaignBanner])
  
/*
  * Sharepoint links tend to give us trouble, so we have to catch them and open them with javascript
  */
function handleLinkClickIfSharepointDomain(e: React.MouseEvent<HTMLDivElement>, banner: AdwCampaignBanner): void {
  if (banner) {
    let url: string = banner.CampaignLink && banner.CampaignLink.Url ? banner.CampaignLink.Url : "/#";
    if (banner.WindowTarget === "_blank") {
      e.preventDefault();
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  }
}

  return (<>{campaignBanner &&
    <div
      id="events-campaign-banner"
      className={styles.campaignBanner}
      style={{backgroundColor: campaignBanner.BackgroundColor}}
      onClick={e => handleLinkClickIfSharepointDomain(e, campaignBanner)}
    >
      <AwsS3Image src={campaignBanner.ImagePath} id={`campaign-banner-aws-img-main-background`} 
          alt={campaignBanner && campaignBanner.CampaignLink && campaignBanner.CampaignLink.Description ? campaignBanner.CampaignLink.Description : " "}
          className={styles.campaignBanner}/>
      <span className={styles.screenReaderOnly}>
        {`This link will open in ${campaignBanner.WindowTarget === "_blank" ? "a new" : "the same"} window`}
      </span>
    </div>
}</>);
};

export default CampaignBanner;

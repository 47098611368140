export type GraphUser = {
  "@odata.context": string;
  businessPhones: string[];
  displayName: string;
  givenName: string;
  jobTitle: string;
  mail: string;
  mobilePhone: string[];
  officeLocation: string;
  preferredLanguage: string;
  surname: string;
  userPrincipalName: string;
  id: string;
  lastPasswordChangeDateTime:string;
};
export type GraphUserData = {
  "@odata.context": string;
  businessPhones: string[];
  displayName: string;
  givenName: string;
  jobTitle: string;
  employeeType
  mail: string;
  mobilePhone: string[];
  officeLocation: string;
  preferredLanguage: string;
  surname: string;
  userPrincipalName: string;
  id: string;
  onPremisesSamAccountName:string;
  department:string;
};
export type GraphAvatarData = {
  "@odata.context": string;
  displayName: string;
  jobTitle: string;
  userPrincipalName: string;
  onPremisesSamAccountName: string;
  surname: string;
  tabOnce: boolean;
  tabTwice: boolean;
  manager: GraphAvatarData;
  employeeType: string;
  id: string;
};

export type GraphUserPersonalizedEmail = {
  "@odata.context": string;
  "@odata.count": number,
  value:[
    {
      userPrincipalName:string;
      onPremisesSamAccountName:string;
    }
  ]
};

export type AvatarAssociateData = {
  "@odata.context": string;
  value:GraphAvatarData[]
};

export type DirectReportAssociateData = {
  "@odata.context": string;
  value:GraphUserData[]
};

export function getDefaultGraphUser(): GraphUser {
  return {
    "@odata.context": "",
    id: "-1",
    businessPhones: [],
    displayName: "",
    givenName: "",
    jobTitle: "",
    mail: "default@nationwide.com",
    mobilePhone: null,
    officeLocation: null,
    preferredLanguage: null,
    surname: "",
    userPrincipalName: "",
    lastPasswordChangeDateTime:""
  };
}

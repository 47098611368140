"use client";
import React, { useState, useEffect, useContext, useRef } from "react";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import AdwCommonTool from "../../../types/CommonToolsTypes/AdwCommonTool";
import AdwNewsStory from "../../../types/CompanyNewsTypes/AdwNewsStory";
import FeaturedStory from "../../molecules/FeaturedStory/FeaturedStory";
import MoreNewsLink from "../../molecules/MoreNewsLink/MoreNewsLink";
import StorySnapshot from "../../molecules/StorySnapshot/StorySnapshot";
import styles from "./CompanyNews.module.scss";
import ComponentErrorMessage from "../ComponentErrorMessage/ComponentErrorMessage";
import { ErrorBoundary } from "react-error-boundary";
import ErrorReportingService from "../../../services/ErrorReportingService";
import { InsideUserData } from "../../../types/InsideUserData";
import AnnouncementSpace from "../AnnouncementsSpace/AnnouncementsSpace";
import AnnouncementFooterSpace from "../AnnouncementFooterSpace/AnnouncementFooterSpace";

export interface ICompanyNewState {
  toolsToDisplay: AdwCommonTool[];
  preferredTools: string;
}
export interface ICompanyNewsViewProps {
  storySnapshotGrid: string;
  moreNewsGrid: string;
  featuredStoryGrid: string;
  announcementsGrid: string;
  allAnnouncementsGrid: string;
}

const CompanyNewsView: React.FC<ICompanyNewsViewProps> = ({
  storySnapshotGrid,
  moreNewsGrid,
  featuredStoryGrid,
  announcementsGrid,
  allAnnouncementsGrid
}) => {
  const adwContext = useContext(AdwContext);
  const [toolsToDisplay, setToolsToDisplay] = useState(null);
  const [appState] = adwContext as AdwContextType;
  const initialRender = useRef(true);
  const user: InsideUserData = appState && appState.user;
  const adwCommonTools: AdwCommonTool[] = appState && appState.lists && appState.lists.commonTools;
  const userPreferredCommonTools: string = appState && appState.user && appState.user.preferredCommonTools;
  useEffect(() => {
    if (appState && appState.lists && appState.lists.commonTools) {
      const initialTools = determineDefaultOrPreferredTools();
      setToolsToDisplay(initialTools);
    }
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (
        adwCommonTools && adwCommonTools.length && user && toolsToDisplay
      ) {
        let currentPreferredTools = '';
       if(toolsToDisplay){
        currentPreferredTools = toolsToDisplay
        .map((tool: any) => tool.ID)
        .join(", ");
       }
       
        if (currentPreferredTools !== userPreferredCommonTools) {
          setToolsToDisplay(determineDefaultOrPreferredTools());
        }
      }
    }
  }, [adwCommonTools,userPreferredCommonTools]);

  let topStories: AdwNewsStory[] =
    (appState && appState.lists && appState.lists.companyNews) || [];
  const featuredStory: AdwNewsStory =
    topStories && topStories.length ? topStories[0] : null;
  topStories =
    topStories && topStories.length ? topStories.slice(1, 5) : topStories;

  const determineDefaultOrPreferredTools = (): AdwCommonTool[] => {
    let toolsForDisplay: AdwCommonTool[] = [];
    if (userPreferredCommonTools) {
      toolsForDisplay = AdwCommonTool.getPrefferedTools(
        userPreferredCommonTools,
        adwCommonTools
      );
    } else {
      toolsForDisplay = adwCommonTools.filter(
        (tool) => tool.DefaultTool || tool.RequiredTool
      );
    }
    return toolsForDisplay;
  };

  return (
    <>
      <ErrorBoundary
        fallback={<ComponentErrorMessage parentClass={featuredStoryGrid} />}
        onError={(error, info) => {
          ErrorReportingService.reportErrorWithMessage(
            error.toString(),
            "ErrorBoundary.tsx -> ComponentErrorMessage -> FeaturedStory",
            appState.user.nwieID
          )
        }}>
        {featuredStory && (
          <FeaturedStory
            featuredStory={featuredStory}
            featuredStoryStyles={featuredStoryGrid}
          />
        )}
        {/* <FeaturedStoryLink/> */}
      </ErrorBoundary>
      <ErrorBoundary
        fallback={<ComponentErrorMessage parentClass={storySnapshotGrid} />}
        onError={(error,info)=>{ 
          ErrorReportingService.reportErrorWithMessage(
          error.toString(),
          "ErrorBoundary.tsx -> ComponentErrorMessage -> StorySnapshot",
          appState.user.nwieID
        )}}>
        <div className={storySnapshotGrid}>
          {topStories &&
            topStories.map((story: AdwNewsStory, index: number) => {
              return <StorySnapshot
                story={story}
                index={index}
                key={story.id}
              />
            })}
        </div>
      </ErrorBoundary>
      <MoreNewsLink
        linkStyles={styles.link}
        moreNewsStyles={`${styles.moreNews} ${moreNewsGrid}`}
        id={`companyNews-MoreNews-link`}
      />
      <ErrorBoundary
        fallback={<ComponentErrorMessage parentClass={announcementsGrid} />}
        onError={(error,info)=>{ 
          ErrorReportingService.reportErrorWithMessage(
          error.toString(),
          "ErrorBoundary.tsx -> ComponentErrorMessage -> Announcements",
          appState.user.nwieID
        )}}>
          <AnnouncementSpace announcementsGrid={announcementsGrid} toolsToDisplay={toolsToDisplay}/>
      </ErrorBoundary>
      <AnnouncementFooterSpace
        linkStyles={styles.link}
        moreNewsStyles={`${styles.moreNews} ${allAnnouncementsGrid}`}
      />
    </>
  );
};

export default CompanyNewsView;

import { toInteger } from "lodash";
import BrowserUtils from "./BrowserUtils";

export default class TimingUtils {
  
  static componentsLoaded: string[] = [];
  static allComponents = ['Events', 'AtoZ', 'Tools', 'News', 'UserPreferences', 'Announcements'];
  static dataSent = false;

  public static isDataSent(): boolean { 
    return this.dataSent;
  }


  public static registerComponentLoad(componentName: string): void {
    if (!this.componentsLoaded.includes(componentName)) {
      this.componentsLoaded.push(componentName);
      this.checkComponentsLoaded();
    }
  }

  public static checkComponentsLoaded(): void {
    for(let componentToCheck of this.allComponents){
      if (!this.componentsLoaded.includes(componentToCheck)) {
        return;
      }
    }
    if (window.location.pathname === '/' && !BrowserUtils.isIframe()) {
      this.sendTimingData();
    }    
  }

  public static sendTimingData(): void {
    this.dataSent = true;
    if (window['newrelic']) {
      window['newrelic'].addPageAction('All components loaded', this.getCustomAttributes());
    }
  }

  public static clearRegisteredComponents(): void {
    this.componentsLoaded = [];
    this.dataSent = false;
  }

  private static getCustomAttributes() {
    /*
      Try to get full duration from start of page navigation to last registered component load.
      PerformanceNavigationTiming returns '0' as the navigation start time and then the rest of
      the timings are based off of that, including the domContentLoadedEventStart (How many 
      milliseconds since navigationStart did x occur).

      index.tsx records the current timestamp in localStorage which should be one of the first
      things to run as the dom content starts to load.

      This code tries to calculate the timestamp in milliseconds of the navigation start time by
      taking the appLoadStart - domContentLoadedEventStart. Then calculates full duration by
      taking the current timestamp - navigation start time
    */
    let startTimeString = localStorage.getItem('appLoadStart');
    let startTime = toInteger(startTimeString);
    let domContentLoadedEventStart = 
      (window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming).domContentLoadedEventStart;
    startTime = startTime - domContentLoadedEventStart;
    
    let duration = (Date.now() - startTime) / 1000;

    // Get location and department from localStorage
    let location = localStorage.getItem("insideUserLocation");
    let department = localStorage.getItem("insideUserDepartment");

    return {
      duration: duration,
      location: location,
      department: department
    };
  }
}